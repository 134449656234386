import api from '@/services/api';

const url = 'category';

const getCategory = () => api.get(url);

const createCategory = (category) => api.post(url, category);

const updateCategory = (category) => api.put(url, category);

const deleteCategory = (id) => api.delete(`${url}/${id}`);

export default {
  getCategory, createCategory, updateCategory, deleteCategory,
};
